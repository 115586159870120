/** @jsxImportSource @emotion/react */

import { Stack, Typography } from "@mui/material";
import AnimatedCloverImages from "./AnimatedCloverImages";

const DashboardBanner = () => {
	return (
		<Stack
			direction="row"
			alignItems={"center"}
			justifyContent={"space-between"}
			sx={{
				backgroundColor: "primary.main",
				borderRadius: "70px",
				px: 13,
				minHeight: 230,
				py: 9,
				marginTop: 6,
				overflow: "visible",
			}}
		>
			<AnimatedCloverImages
				image1={{ src: "/images/dashboard/pill1.png", alt: "Girl drinking with a straw" }}
				image2={{ src: "/images/dashboard/pill3.png", alt: "Girl doing yoga" }}
				image3={{ src: "/images/dashboard/pill2.png", alt: "People partying" }}
			/>
			<Stack>
				<Typography children="THINK DIGITAL, LIVE FOR REAL" variant="h4" sx={{ maxWidth: 627 }} />
				<Typography variant="h6" fontWeight={"regular"} sx={{ maxWidth: 565, pt: 6 }}>
					La biglietteria digitale più innovativa per creare, gestire e promuovere il tuo evento.
					<br /> Ma anche per scoprire, partecipare e condividere. <br />
					Entra in Nowr ed esci di casa, life is Nowr.
				</Typography>
			</Stack>
		</Stack>
	);
};
export default DashboardBanner;
