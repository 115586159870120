/** @jsxImportSource @emotion/react */

import { Stack } from "@mui/material";
import { animated, config, useChain, useSpring, useSpringRef, to } from "react-spring";

type ImageProps = {
	src: string;
	alt: string;
};
type AnimatedCloverImagesProps = {
	image1: ImageProps;
	image2: ImageProps;
	image3: ImageProps;
	overrideHeight?: number;
};
const AnimatedCloverImages: React.FC<AnimatedCloverImagesProps> = ({ image1, image2, image3, overrideHeight }) => {
	const translateRef = useSpringRef();
	const translateAnimation = useSpring({
		translateY: 0,
		from: { translateY: -100 },
		config: config.gentle,
		ref: translateRef,
	});
	const rotateRef = useSpringRef();
	const rotateAnimation = useSpring({
		rotate: "20deg",
		translateY: 15,
		from: { rotate: "0deg", translateY: 0 },
		config: config.gentle,
		ref: rotateRef,
	});

	useChain([translateRef, rotateRef]);

	return (
		<Stack
			alignItems={"center"}
			justifyContent="center"
			sx={{
				width: 407,
				height: overrideHeight ?? 154,
				position: "relative",
				filter: "drop-shadow(0px -8px 17px rgba(0, 0, 0, 0.06))",
				flexShrink: 0,
			}}
		>
			<animated.img
				src={image1.src}
				alt={image1.alt}
				style={{
					transformOrigin: "50% 200%",
					transform: to(
						[translateAnimation.translateY, rotateAnimation.rotate, rotateAnimation.translateY],
						(trans, rot, transAfter) => `translateY(${(trans as number) + (transAfter as number)}px) rotate(-${rot})`
					),
				}}
				css={{ position: "absolute", top: -100 }}
			/>
			<animated.img
				src={image2.src}
				alt={image2.alt}
				style={{
					transformOrigin: "50% 200%",
					transform: to(
						[translateAnimation.translateY, rotateAnimation.rotate, rotateAnimation.translateY],
						(trans, rot, transAfter) => `translateY(${(trans as number) + (transAfter as number)}px) rotate(${rot})`
					),
				}}
				css={{ position: "absolute", top: -100 }}
			/>
			<animated.img
				src={image3.src}
				alt={image3.alt}
				style={{ ...translateAnimation }}
				css={{ position: "absolute", top: -100 }}
			/>
		</Stack>
	);
};
export default AnimatedCloverImages;
